@import 'colors';

button {
  &[appearance='outline'],
  &[appearance='transparent'],
  &[appearance='secondary-red'],
  &[appearance='secondary-green'] {
    &[data-size='s'],
    &[data-size='xl'],
    &[data-size='xs'] {
      height: 40px;
      border-radius: 6px;
      background-color: @background-color-active;
      font-weight: 500;
      font-size: 14px;
      //color: @color-active;
      border: none;

      &:hover {
        opacity: 0.8;
      }

      &[disabled] {
        &:hover {
          opacity: 1;
        }
      }

      .t-wrapper {
        &::after {
          border: none;
        }
      }
    }

    &[data-size='xs'] {
      height: 24px;
      font-size: 13px;
    }

    &[data-size='xl'] {
      font-size: 14px;
    }

    &.icon {
      width: 40px;

      tui-svg {
        transition: transform 0.2s ease-in-out;
      }

      &:hover:not([disabled]) {
        tui-svg:not(.non-transition) {
          transform: scale(1.2);
        }
      }
    }
  }

  &[data-appearance='primary'],
  &[data-appearance='flat'] {
    border-radius: 6px;
    font-weight: 500;

    &[data-size='s'] {
      height: 40px;
    }

    &[data-size='xs'] {
      height: 32px;
      font-size: 13px;
    }
  }

  &[appearance='outline'] {
    background: var(--tui-base-07);

    .t-wrapper {
      span {
        color: @color-active;
      }
    }
  }

  &[appearance='transparent'] {
    &[data-size='s'],
    &[data-size='xs'] {
      background: none;

      tui-svg {
        color: @text-2;
      }

      &:hover {
        color: var(--tui-primary-hover);

        tui-svg {
          color: var(--tui-primary-hover);
        }

        .t-wrapper {
          &::after {
            border: 2px solid var(--tui-primary-hover);
          }
        }
      }

      .t-wrapper {
        &::after {
          border: 2px solid var(--tui-base-03);
        }

        &[data-state='active'] {
          color: var(--tui-primary);

          tui-svg {
            color: var(--tui-primary);
          }

          &::after {
            border: 2px solid var(--tui-primary);
          }
        }
      }
    }

    &[data-size='xs'] {
      height: 30px;
      font-size: 13px;

      .t-wrapper span {
        padding: 0 10px !important;
      }
    }

    &[disabled] {

      &:hover, span {
        background: none;
        color: @text-2;
        opacity: 1;

        tui-svg {
          color: @text-2;
        }

        .t-wrapper {
          &::after {
            border: 2px solid var(--tui-base-03);
          }
        }
      }
    }
  }

  &[data-appearance='transparent'] {
    &[data-size='xs'] {
      .t-wrapper {
        padding: 0 !important;
      }
    }
  }

  &[appearance='secondary-red'] {
    &[data-size='s'] {
      background: @light-red;
      color: @text-dark-red;

      & tui-svg {
        color: @text-dark-red;
      }

      &:hover, &:hover:not([disabled]) {
        color: @color-active;
        opacity: 0.8;
      }
    }

    &[data-size='xs'] {
      height: 24px;
      font-size: 13px;
    }
  }

  &[appearance='secondary-green'] {
    &[data-size='s'] {
      background: @light-green;
      color: @text-dark-green;

      & tui-svg {
        color: @text-dark-green;
      }

      &:hover, &:hover:not([disabled]) {
        color: @color-active;
        opacity: 0.8;
      }
    }

    &[data-size='xs'] {
      height: 24px;
      font-size: 13px;
    }
  }

  &.count {
    position: relative;

    &::before {
      position: absolute;
      z-index: 2;
      content: attr(data-value);
      top: -11px;
      right: -10px;
      width: fit-content;
      height: auto;
      padding: 0 4px;
      background-color: #1ae9a4;
      border-radius: 4px;
      color: black;
      font-size: 12px;
    }
  }

  &[tuiIconButton] {
    width: 40px;
  }
}
